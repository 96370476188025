import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";
import cancelRequestOnRouteChange from "@/services/cancelRequestOnRouteChange";
import routes from "./routes";
import store from "../store/index";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

// 路由拦截器
router.beforeEach(async function (to, from, next) {
    if (window.g.openCancelRequest) cancelRequestOnRouteChange();

    // if (to.query.token) {
    //   localStorage.removeItem('userInfo')
    //   localStorage.removeItem('monitorAreas')
    //   localStorage.removeItem('monitorAreasCheckedIndex')
    //   localStorage.setItem('urlToken', to.query.token)
    //   try {
    //     const res = await logintest()
    //     console.log('file: index.js ~ line 140 ~ res', res)
    //     localStorage.setItem('userInfo', JSON.stringify(res.data.userinfo))

    //     // const a = await monitorAreas()
    //     // const checkedIndex = 0
    //     // let areas = []

    //     // if (a.code == 1 && a.data.areas.length > 0) {
    //     //   areas = a.data.areas
    //     //   localStorage.setItem('monitorAreas', JSON.stringify(areas))
    //     //   localStorage.setItem('monitorAreasCheckedIndex', checkedIndex)
    //     // }

    //     // if (areas.length === 0)
    //     //   Message({
    //     //     message: '矿区列表为空',
    //     //     type: 'warning',
    //     //   })
    //     next('/main-layout/home')
    //   } catch (error) {
    //     Message.error(error)
    //     next('/')
    //   }
    // }
    // let userInfo = JSON.parse(localStorage.getItem('userInfo'))

    // if (userInfo) {
    next();
    // } else {
    //   if (to.path === '/login') {
    //     next()
    //   } else {
    //     next(`/login?redirect=${to.path}`)
    //   }
    // }
});
router.onError((error) => {
    if (error instanceof NavigationDuplicated) {
        // 忽略这个错误，或者你可以选择重新导航到 `error.to` 路径
        router.push(error.to);
    }
});
export default router;
