import { get, post } from "../services/http";
//登录接口
export function login(params = {}) {
    return post(`/login`, params);
}
//用户信息
export function getInfo(params = {}) {
    return get(`/getInfo`, params);
}
// /查询主题模块列表
export function get_theme_list(params = {}) {
    return get(`/thememodule/theme/list`, params);
}
//新增聊天窗口
export function add_chatWin(params = {}) {
    return post(`/chatwindow/chatwindow/addChatWin`, params);
}
//根据用户Id查询聊天窗口列表
export function list_by_user_id(params = {}) {
    return get(`/chatwindow/chatwindow/listByUserId`, params);
}

//用户注册
export function register(params = {}) {
    return post(`/register`, params);
}
//根据用户Id查询聊天窗口列表
export function consume_points(params = {}) {
    return get(`/points/points/consume_points`, params);
}
//绑定好学堂订单号
export function generateAuthCode(params = {}) {
    return get(`/thememodule/theme/generateAuthCode`, params);
}
//校验授权码及积分
export function checkAuthCode(params = {}) {
    return get(`/thememodule/theme/checkAuthCode`, params);
}
//查询服务条款和隐私政策列表
export function policies_list(params = {}) {
    return get(`/thememodule/policies/list`, params);
}