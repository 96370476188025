<template>
  <div class="voice-dialogue-box">
    <div class="voice-dialogue-title">
      <i class="el-icon-arrow-left" @click="handleArrowLeft"></i>
      <div class="vdt-text">语音对话</div>
      <div></div>
    </div>
    <div class="voice-state-box">
      <div class="voice-state-iner">
        <div class="voice-state-inner">
          <div v-if="isShow">
            <img src="@/assets/mkf-big-icon.svg" alt="" v-if="!isVoice" />
            <img src="@/assets/speak-icon.svg" alt="" v-if="isVoice" />
          </div>
          <div v-else>
            <div class="loadEffect">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="voice-button-box">
      <div class="voice-button" @click="handleVoiceButton" v-if="isShow">
        <div class="voice-inner-button" :class="{ 'voice-active': isVoice }"></div>
      </div>
      <div class="voice-inner-text" v-if="isShow">
        {{ !isVoice ? "点击录制" : "点击暂停" }}
      </div>
      <div
        class="voice-button voice-button-isShow"
        @click="handleVoiceButton"
        v-else
      ></div>
    </div>
  </div>
</template>
<script>
import { chat_audio } from "@/api/air";
export default {
  name: "VoiceDialogue",
  props: {
    params: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isVoice: false,
      audioContext: null,
      audioStream: null,
      scriptProcessor: null,
      audioChunks: [],
      isShow: true,
      currentAudio: null,
    };
  },
  created() {
    console.log(this.params, "---params");
  },
  beforeDestroy() {
    // Stop the audio when the component is destroyed (e.g., leaving the page)
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
    }
  },
  methods: {
    //点击返回
    handleArrowLeft() {
      //   this.$emit("closeVoicedialogue");
      this.$bus.$emit("closeVoicedialogue");
    },
    async handleVoiceButton() {
      this.isVoice = !this.isVoice;
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio.currentTime = 0;
      }
      if (this.isVoice) {
        await this.startRecording();
      } else {
        await this.stopRecording();
      }
    },
    startRecording() {
      // 处理 getUserMedia 的兼容性
      const getUserMedia = (constraints) => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          return navigator.mediaDevices.getUserMedia(constraints);
        } else if (navigator.getUserMedia) {
          return new Promise((resolve, reject) => {
            navigator.getUserMedia(constraints, resolve, reject);
          });
        } else if (navigator.webkitGetUserMedia) {
          return new Promise((resolve, reject) => {
            navigator.webkitGetUserMedia(constraints, resolve, reject);
          });
        } else if (navigator.mozGetUserMedia) {
          return new Promise((resolve, reject) => {
            navigator.mozGetUserMedia(constraints, resolve, reject);
          });
        } else {
          return Promise.reject(new Error("浏览器不支持 getUserMedia API"));
        }
      };
      // 兼容处理: Safari 中需要在用户手势事件中创建 AudioContext
      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      }
      // 请求麦克风访问权限
      getUserMedia({ audio: true })
        .then((stream) => {
          this.audioStream = stream;
          this.scriptProcessor = this.audioContext.createScriptProcessor(4096, 1, 1);
          const source = this.audioContext.createMediaStreamSource(this.audioStream);
          source.connect(this.scriptProcessor);
          this.scriptProcessor.connect(this.audioContext.destination);
          this.scriptProcessor.onaudioprocess = (event) => {
            const inputBuffer = event.inputBuffer.getChannelData(0);
            const buffer = new Float32Array(inputBuffer.length);
            for (let i = 0; i < inputBuffer.length; i++) {
              buffer[i] = inputBuffer[i];
            }
            this.audioChunks.push(buffer);
          };

          console.log("录音已成功开始");
        })
        .catch((error) => {
          if (
            error.name === "NotAllowedError" ||
            error.name === "PermissionDeniedError"
          ) {
            alert("麦克风访问权限被拒绝。");
          } else if (
            error.name === "NotFoundError" ||
            error.name === "DevicesNotFoundError"
          ) {
            alert("未找到音频输入设备。");
          } else {
            alert("访问音频流时出错：" + error.message);
          }
        });
    },
    /**
     * 停止录音，并处理已记录的音频数据。
     * 这个方法断开了音频处理链路，并关闭了音频上下文。它还停止了音频流中的所有轨道，
     * 并将已记录的音频块合并成一个单一的音频数据，然后对这个数据进行字节转换，
     * 为后续处理（如保存或发送）做好准备。
     */
    async stopRecording() {
      try {
        // 断开脚本处理器与音频上下文的连接
        this.scriptProcessor.disconnect();
        // // 关闭音频上下文
        // this.audioContext.close;
        // 停止音频流中的所有轨道
        this.audioStream.getTracks().forEach((track) => track.stop());
        // 合并音频块
        const audioData = this.concatenateAudioChunks(this.audioChunks);
        // 将浮点数音频数据转换为 WAV 格式的字节数组
        const wavData = this.convertFloat32ToWav(audioData);
        // 输出转换后的字节数组，用于调试或进一步处理
        this.params.order++;
        let formData = new FormData();
        formData.append("audio", new Blob([wavData], { type: "audio/wav" }), "audio.wav");
        // 将参数添加到 FormData 对象中
        for (let key in this.params) {
          if (this.params.hasOwnProperty(key)) {
            formData.append(key, this.params[key]);
          }
        }
        this.isShow = false;

        chat_audio(formData).then((res) => {
          if (res.code == 200) {
            this.isShow = true;
            this.playBase64Audio(res.data.response_audio_bytes);
          }
        });
        // 重置音频块数组，为下一次录音做准备
        // 重置录音相关的状态
        this.audioChunks = [];
      } catch (error) {
        // 捕获并记录停止录音过程中可能出现的错误
        console.error("Error stopping recording: ", error);
      }
    },
    //播放返回音频
    // playBase64Audio(base64Audio) {
    //   // Decode base64 string to binary data
    //   const binaryString = atob(base64Audio);
    //   const binaryLen = binaryString.length;
    //   const bytes = new Uint8Array(binaryLen);
    //   for (let i = 0; i < binaryLen; i++) {
    //     bytes[i] = binaryString.charCodeAt(i);
    //   }
    //   // Create a Blob from the binary data
    //   const blob = new Blob([bytes], { type: "audio/wav" });
    //   // Generate a URL from the Blob
    //   const url = URL.createObjectURL(blob);
    //   // Create an audio element and play the audio

    //   const audio = new Audio(url);
    //   audio.play();
    // },

    playBase64Audio(base64Audio) {
      // Stop the current audio if it's playing
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio.currentTime = 0; // Reset playback position
      }

      // Decode base64 string to binary data
      const binaryString = atob(base64Audio);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the binary data
      const blob = new Blob([bytes], { type: "audio/wav" });
      // Generate a URL from the Blob
      const url = URL.createObjectURL(blob);

      // Create an audio element and play the audio
      const audio = new Audio(url);
      this.currentAudio = audio;
      audio.play();
    },
    concatenateAudioChunks(chunks) {
      const length = chunks.reduce((sum, chunk) => sum + chunk.length, 0);
      const result = new Float32Array(length);
      let offset = 0;
      for (let chunk of chunks) {
        result.set(chunk, offset);
        offset += chunk.length;
      }
      return result;
    },
    convertFloat32ToByteArray(float32Array) {
      const byteArray = new Uint8Array(float32Array.length * 4);
      for (let i = 0; i < float32Array.length; i++) {
        const value = float32Array[i];
        const intValue = value * (value < 0 ? 0x80000000 : 0x7fffffff);
        byteArray[i * 4] = (intValue >> 24) & 0xff;
        byteArray[i * 4 + 1] = (intValue >> 16) & 0xff;
        byteArray[i * 4 + 2] = (intValue >> 8) & 0xff;
        byteArray[i * 4 + 3] = intValue & 0xff;
      }
      return byteArray;
    },
    convertFloat32ToWav(float32Array) {
      const sampleRate = this.audioContext.sampleRate;
      const numChannels = 1;
      const buffer = new ArrayBuffer(44 + float32Array.length * 2);
      const view = new DataView(buffer);

      function writeString(view, offset, string) {
        for (let i = 0; i < string.length; i++) {
          view.setUint8(offset + i, string.charCodeAt(i));
        }
      }
      const floatTo16BitPCM = (output, offset, input) => {
        for (let i = 0; i < input.length; i++, offset += 2) {
          const s = Math.max(-1, Math.min(1, input[i]));
          output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
        }
      };
      // RIFF chunk descriptor
      writeString(view, 0, "RIFF");
      view.setUint32(4, 36 + float32Array.length * 2, true);
      writeString(view, 8, "WAVE");
      // FMT sub-chunk
      writeString(view, 12, "fmt ");
      view.setUint32(16, 16, true);
      view.setUint16(20, 1, true); // PCM format
      view.setUint16(22, numChannels, true);
      view.setUint32(24, sampleRate, true);
      view.setUint32(28, sampleRate * 2, true); // byte rate
      view.setUint16(32, numChannels * 2, true); // block align
      view.setUint16(34, 16, true); // bits per sample
      // Data sub-chunk
      writeString(view, 36, "data");
      view.setUint32(40, float32Array.length * 2, true);
      floatTo16BitPCM(view, 44, float32Array);
      return view;
    },
  },
};
</script>
<style lang="scss">
.voice-dialogue-box {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("@/assets/voice-dialogue-bg.png");
  background-color: #fff;
  background-size: 100% 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .voice-dialogue-title {
    padding-top: 16px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .vdt-text {
      margin-left: -40px;
      font-size: 16px;
      font-weight: bold;
      color: #3d3d3d;
    }
  }
  .voice-state-box {
    display: flex;
    justify-content: center;
    .voice-state-iner {
      margin-top: -250px;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background: linear-gradient(90deg, #e9f3fd 0%, #eaecfe 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      .voice-state-inner {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        .loadEffect {
          width: 100px;
          height: 100px;
          position: relative;
          margin: 0 auto;
        }
        .loadEffect span {
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: rgb(87, 137, 253);
          position: absolute;
          animation: load 1.04s ease infinite;
        }

        @keyframes load {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0.2;
          }
        }
        .loadEffect span:nth-child(1) {
          left: 0;
          top: 50%;
          margin-top: -8px;
          -webkit-animation-delay: 0.13s;
        }
        .loadEffect span:nth-child(2) {
          left: 14px;
          top: 14px;
          -webkit-animation-delay: 0.26s;
        }
        .loadEffect span:nth-child(3) {
          left: 50%;
          top: 0;
          margin-left: -8px;
          -webkit-animation-delay: 0.39s;
        }
        .loadEffect span:nth-child(4) {
          top: 14px;
          right: 14px;
          -webkit-animation-delay: 0.52s;
        }
        .loadEffect span:nth-child(5) {
          right: 0;
          top: 50%;
          margin-top: -8px;
          -webkit-animation-delay: 0.65s;
        }
        .loadEffect span:nth-child(6) {
          right: 14px;
          bottom: 14px;
          -webkit-animation-delay: 0.78s;
        }
        .loadEffect span:nth-child(7) {
          bottom: 0;
          left: 50%;
          margin-left: -8px;
          -webkit-animation-delay: 0.91s;
        }
        .loadEffect span:nth-child(8) {
          bottom: 14px;
          left: 14px;
          -webkit-animation-delay: 1.04s;
        }
      }
    }
  }
  .voice-button-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .voice-button {
      margin-top: -258px;
      width: 60px;
      height: 60px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.2);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .voice-inner-button {
        transform: 5.5s;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: linear-gradient(92deg, #4e93f5 1%, #827cf7 98%);
        box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.2);
        transition: all 0.2s ease-in-out;
      }
      .voice-inner-button.voice-active {
        width: 24px;
        height: 24px;
        background: linear-gradient(92deg, #f54e4e 1%, #f77c7c 98%);
        border-radius: 9px;
        animation: swing 0.5s ease-in-out;
      }
      @keyframes swing {
        0% {
          transform: translateX(0);
        }
        25% {
          transform: translateX(10px);
        }
        50% {
          transform: translateX(-10px);
        }
        75% {
          transform: translateX(5px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
    .voice-inner-text {
      margin-top: 11px;
      font-size: 14px;
      color: #aaaaaa;
    }
    .voice-button-isShow {
      background: #ffffff00;
      box-shadow: none;
    }
  }
}
</style>
